import React from "react"


export default function Header() {
    return (
                <nav className="nav">
                    <img src={require("../images/bestimg.jpg")} className="my-face" alt = ""/>
                    <ul className="nav-items">
                        <li>
                            Projects
                        </li>
                        <li>
                            About
                        </li>
                        <li>
                            Contact
                        </li>
                    </ul>
                </nav>
    )
}