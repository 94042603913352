import React from "react"

export default function Main() {
    return (
    <main className="mainclass">
            <h1 className="top-body">
                About me!
            </h1>
            <ul className="facts">
                <li>
                    Looking for a job in the software field!
                </li>
                <li>
                    Created this website using React, HTML, and CSS
                </li>
                <li>
                    Using the top right buttons will take you to more information
                </li>
            </ul>
            <a href = "https://github.com/aaronp1545"
            target = "_blank"
            rel= "noopener no refferer"
            >
            click me!   
            </a>

            <a href="https://www.linkedin.com/in/aaronparelius/"
                target="_blank"
                rel="noopener no refferer"
            >
                click me!
            </a>
    </main>
    )
}
