import React from "react"
import ReactDOM from "react-dom"
import './index.css'
import Header from "./components/Header"
import Footer from "./components/Footer"
import Main from "./components/Main"


function Page() {
  return (
    <div>
      <Header/>
      <p></p>
      <Main/>
      <Footer/>
    </div>
  )
}

ReactDOM.render(<Page/>, document.getElementById("root"))