import React from "react"


export default function Footer() {
        return (
            <div className="foot">
                <footer>
                    <ol>
                        <small>
                            @ 2023 Parelius development. All rights reserved.
                        </small>
                    </ol>
                </footer>
            </div>
        )
    }
